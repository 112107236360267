import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer>
        <div className="footer__area-two footer__area-six">
            <div className="footer__top-two">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-7">
                            <div className="footer-widget">
                                <div className="footer__content-two">
                                    <div className="fw-logo mb-25">
                                        <a href="/"><img src="assets/img/logo/w_logo.png" alt=""/></a>
                                    </div>
                                    <p className="color-white mb-40">Felis consquat magnis fames sagittis ultrices plasodales porttitor</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-1"></div>
                        <div className="col-xl-1"></div>
                        <div className="col-xl-2 col-lg-3 col-md-5 col-sm-6">
                            <div className="footer-widget">
                                <h4 className="fw-title">Menu</h4>
                                <div className="footer-link-list">
                                    <ul className="list-wrap">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="#">Practice</a></li>
                                        <li><a href="#">Account</a></li>
                                        <li><a href="#">Support</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-sm-6">
                            <div className="footer-widget">
                                <h4 className="fw-title">Information</h4>
                                <div className="footer-link-list">
                                    <ul className="list-wrap">
                                        <li><a href="#">FAQ</a></li>
                                        <li><a href="#">Terms of Use</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__bottom-two">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="copyright-text-two">
                                <p>Copyright © <a href="/">XYZ</a> | All Right Reserved</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="footer__social-two">
                                <ul className="list-wrap">
                                    <li><a href="javascript:void(0)"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="javascript:void(0)"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="javascript:void(0)"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="javascript:void(0)"><i className="fab fa-pinterest-p"></i></a></li>
                                    <li><a href="javascript:void(0)"><i className="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
