import React from 'react';

const CategoryManagement: React.FC = () => {
  // Replace this with your actual admin check
  const isAdmin = true; 

  return isAdmin ? <div>Category Management Page</div> : <div>Access Denied</div>;
};

export default CategoryManagement;
