import React from 'react';

const Home: React.FC = () => {
  return (
    <>
    <section className="slider__area">
        <div className="swiper-container slider_baner__active slider_baner_home6">
            <div className="swiper-wrapper">
                <div className="swiper-slide slider__single">
                    <div className="slider__bg" style={{backgroundImage: 'url("assets/img/home6/banner.png")'}}></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="banner__content-three">
                                    <h2 className="title">Neque porro quisquam </h2>
                                    <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce pretium, felis in eleifend maximus, odio velit molestie tortor, vel ultricies augue magna quis nisi.
                                    </p>
                                    <a href="#" className="btn">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="pricing__area pricing__bg" data-background="assets/img/bg/pricing_bg.jpg">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-5">
                    <div className="section-title text-center mb-30">
                        <h2 className="title">Categories</h2>
                    </div>
                </div>
            </div>
            <div className="pricing__item-wrap">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="pricing__box">
                            <div className="pricing__head">
                                <h5 className="title">CCSP - 2022</h5>
                            </div>
                            <div className="pricing__price">
                                <h2 className="price monthly_price"><strong>$</strong> 15.00 </h2>
                            </div>
                            <div className="pricing__btn">
                                <a href="javascript:void(0)" className="btn">Get this Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="pricing__box">
                            <div className="pricing__head">
                                <h5 className="title">CISA - 2019</h5>
                            </div>
                            <div className="pricing__price">
                                <h2 className="price monthly_price"><strong>$</strong> 29.00 </h2>
                            </div>
                            <div className="pricing__btn">
                                <a href="javascript:void(0)" className="btn">Get this Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="pricing__box">
                            <div className="pricing__head">
                                <h5 className="title">CISM - 2022</h5>
                            </div>
                            <div className="pricing__price">
                                <h2 className="price monthly_price"><strong>$</strong> 89.00 </h2>
                            </div>
                            <div className="pricing__btn">
                                <a href="javascript:void(0)" className="btn">Get this Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="pricing__box">
                            <div className="pricing__head">
                                <h5 className="title">CISSP - 2021</h5>
                            </div>
                            <div className="pricing__price">
                                <h2 className="price monthly_price"><strong>$</strong> 105.00 </h2>
                            </div>
                            <div className="pricing__btn">
                                <a href="javascript:void(0)" className="btn">Get this Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="pricing__box">
                            <div className="pricing__head">
                                <h5 className="title">CCSP - 2022</h5>
                            </div>
                            <div className="pricing__price">
                                <h2 className="price monthly_price"><strong>$</strong> 15.00 </h2>
                            </div>
                            <div className="pricing__btn">
                                <a href="javascript:void(0)" className="btn">Get this Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="pricing__box">
                            <div className="pricing__head">
                                <h5 className="title">CISA - 2019</h5>
                            </div>
                            <div className="pricing__price">
                                <h2 className="price monthly_price"><strong>$</strong> 29.00 </h2>
                            </div>
                            <div className="pricing__btn">
                                <a href="javascript:void(0)" className="btn">Get this Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="pricing__box">
                            <div className="pricing__head">
                                <h5 className="title">CISM - 2022</h5>
                            </div>
                            <div className="pricing__price">
                                <h2 className="price monthly_price"><strong>$</strong> 89.00 </h2>
                            </div>
                            <div className="pricing__btn">
                                <a href="javascript:void(0)" className="btn">Get this Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="pricing__box">
                            <div className="pricing__head">
                                <h5 className="title">CISSP - 2021</h5>
                            </div>
                            <div className="pricing__price">
                                <h2 className="price monthly_price"><strong>$</strong> 105.00 </h2>
                            </div>
                            <div className="pricing__btn">
                                <a href="javascript:void(0)" className="btn">Get this Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="pricing__shape-wrap">
            <img src="assets/img/images/pricing_shape01.png" alt="" data-aos="fade-right" data-aos-delay="400"/>
            <img src="assets/img/images/pricing_shape02.png" alt="" data-aos="fade-left" data-aos-delay="400"/>
        </div>
    </section>
    </>
  );
};

export default Home;
