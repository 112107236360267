import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import CategoryManagement from "./pages/CategoryManagement";
import UserProfile from "./pages/UserProfile";

const App: React.FC = () => {
  const isAdmin = true;
  const isAuthenticated = true;

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Registration />} />
        <Route
          path="/category-management"
          element={isAdmin ? <CategoryManagement /> : <Login />}
        />
        <Route
          path="/profile"
          element={isAuthenticated ? <UserProfile /> : <Login />}
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
