import React from 'react';

const UserProfile: React.FC = () => {
  // Check if user is logged in
  const isAuthenticated = true; 

  return isAuthenticated ? <div>User Profile Page</div> : <div>Access Denied</div>;
};

export default UserProfile;
